import { render, staticRenderFns } from "./UrdfPicker.vue?vue&type=template&id=06b3035e&scoped=true"
import script from "./UrdfPicker.vue?vue&type=script&lang=ts"
export * from "./UrdfPicker.vue?vue&type=script&lang=ts"
import style0 from "./UrdfPicker.vue?vue&type=style&index=0&id=06b3035e&prod&lang=scss&scoped=true"
import style1 from "./UrdfPicker.vue?vue&type=style&index=1&id=06b3035e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b3035e",
  null
  
)

export default component.exports