import graphql from './assetDefinitions.gql';
import { AssetDefinitionGql, AssetDefinitionJson } from './model';
import { client } from '../client';
import { gqlToJson, jsonToGql } from './model';

const FULL_QUERY = {
  query: graphql.assetDefinitions,
  variables: {
    vizVersion: '',
  },
};

export async function upsertAssetDefinition(
  assetDefinition: AssetDefinitionJson
): Promise<AssetDefinitionJson> {
  const { data, errors } = await client.mutate<{
    upsertAssetDefinition: AssetDefinitionGql;
  }>({
    mutation: graphql.upsertAssetDefinition,
    variables: {
      assetDefinition: jsonToGql(assetDefinition),
    },
    update(cache, { data }) {
      if (data) {
        let currentData = cache.readQuery<{
          assetDefinitions: AssetDefinitionGql[];
        }>(FULL_QUERY);
        if (!currentData) currentData = { assetDefinitions: [] };
        cache.writeQuery({
          ...FULL_QUERY,
          data: {
            assetDefinitions: [
              ...currentData.assetDefinitions,
              data.upsertAssetDefinition,
            ],
          },
        });
      }
    },
  });

  if (data) {
    return gqlToJson(data.upsertAssetDefinition);
  } else {
    throw errors;
  }
}

export async function listAssetDefinitions(): Promise<AssetDefinitionJson[]> {
  const { data } = await client.query<{
    assetDefinitions: AssetDefinitionGql[];
  }>(FULL_QUERY);
  return data.assetDefinitions.map(gqlToJson);
}

export async function deleteAssetDefinition(
  id: string,
  vizVersion: string
): Promise<void> {
  const { errors } = await client.mutate<{
    deleteAssetDefinition: void;
  }>({
    mutation: graphql.deleteAssetDefinition,
    variables: {
      id,
      vizVersion,
    },
    update(cache, { data }) {
      if (data) {
        let currentData = cache.readQuery<{
          assetDefinitions: AssetDefinitionGql[];
        }>(FULL_QUERY);
        if (!currentData) currentData = { assetDefinitions: [] };
        cache.writeQuery({
          ...FULL_QUERY,
          data: {
            assetDefinitions: currentData.assetDefinitions.filter(
              x => !(x.id === id && x.vizVersion === vizVersion)
            ),
          },
        });
      }
    },
  });
  if (errors) {
    throw errors;
  }
}
