<template>
  <div class="all">
    <div class="section">
      <h1>Asset Definition Management Service</h1>
      <CreateModal :initialState="selected" :open.sync="showModal" />
      <div class="row">
        <el-input class="search" placeholder="filter..." v-model="search">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button
          class="button"
          type="primary"
          @click.native="showCreateAssetDefinitionModal"
        >
          + New
        </el-button>
        <el-button
          class="button"
          type="primary"
          @click.native="exportAssetDefinitions"
        >
          Export All
        </el-button>
      </div>
    </div>
    <div class="section expand">
      <el-table
        :row-key="getVersionedId"
        :data="assetDefinitionRows"
        :default-sort="{ prop: 'def.name', order: 'ascending' }"
        height="100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="def.name" label="Name" sortable />
        <el-table-column
          width="150"
          prop="def.vizVersion"
          label="Viz Version"
          sortable
        />
        <el-table-column label="urdf" sortable>
          <template slot-scope="scope">
            <a
              v-if="
                Object.keys(urdfs).length > 0 &&
                  urdfs[scope.row.def.urdfId].name !== NO_URDF
              "
              :href="urdfs[scope.row.def.urdfId].url"
              >{{ urdfs[scope.row.def.urdfId].name }}</a
            >
            <div v-else>{{ NO_URDF }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="120">
          <template slot="header">
            <el-button
              v-if="multiSelect.length > 1"
              @click="showBatchUpdateModal"
              round
              type="primary"
              >Batch Update</el-button
            >
            <div v-else>Update</div>
          </template>
          <template slot-scope="scope">
            <el-button
              @click="showUpdateAssetDefinitionModal(scope.row.def)"
              round
              type="primary"
              >Update</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" width="150">
          <template slot="header">
            <div>Danger Zone</div>
          </template>
          <template slot-scope="scope">
            <expert-mode :selection="scope.row.def" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { downloadJson, NO_URDF } from '@/util';
import AssetDefinitionController from '@/clients/apollo/assetDefinitions';
import UrdfController from '@/clients/apollo/urdfs';
import CreateModal from '@/components/CreateModal.vue';
import ExpertMode from '@/components/ExpertMode.vue';
import { AssetDefinitionJson } from '@/clients/apollo/assetDefinitions/model';

export default defineComponent({
  data() {
    return {
      search: '',
      showModal: false,
      selected: [] as AssetDefinitionJson[],
      multiSelect: [] as AssetDefinitionJson[],
      publishing: false,
    };
  },
  components: {
    CreateModal,
    ExpertMode,
  },
  setup() {
    return {
      title: 'Asset Definition Service',
      NO_URDF,
    };
  },
  computed: {
    assetDefinitionRows(): object[] {
      const list = AssetDefinitionController.Instance.assetDefinitionsList as {
        [key: string]: AssetDefinitionJson;
      }[];
      return list
        .map(data => {
          const latest = Math.max(
            ...Object.keys(data).map(revision => parseInt(revision))
          );
          return {
            def: data[latest],
            canUpdate: true,
            children: Object.values(data)
              .filter(def => def.common.revision !== `${latest}`)
              .map(assetDef => ({ def: assetDef })),
          };
        })
        .filter(data => this.searchFilter(data));
    },
    urdfs: function() {
      return UrdfController.Instance.urdfs;
    },
  },
  methods: {
    getVersionedId(row) {
      return `${row.def.id}:${row.def.vizVersion}`;
    },
    searchFilter(data) {
      if (!this.search) {
        return true;
      }

      const assetDefinitionName = data.def.name.toLowerCase();
      const urdfName = this.urdfs[data.def.urdfId].name.toLowerCase();
      const search = this.search.toLowerCase();

      if (search.includes(`"`)) {
        const inBetweenQuotes = /"(.*)"/g.exec(search);
        if (inBetweenQuotes) {
          return (
            assetDefinitionName === inBetweenQuotes[1] ||
            urdfName === inBetweenQuotes[1]
          );
        }
      }
      return assetDefinitionName.includes(search) || urdfName.includes(search);
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
    },
    showCreateAssetDefinitionModal() {
      this.selected = [];
      this.showModal = true;
    },
    showUpdateAssetDefinitionModal(assetDefinition) {
      this.selected = [assetDefinition];
      this.showModal = true;
    },
    showBatchUpdateModal() {
      this.selected = this.multiSelect;
      this.showModal = true;
    },
    async exportAssetDefinitions() {
      downloadJson({
        urdfs: await UrdfController.Instance.exportUrdfs(),
        assetDefinitions: await AssetDefinitionController.Instance.exportAssetDefinitions(),
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/variables';
.all {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 80%;
  height: 90%;
  margin: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search {
  padding-right: 10px;
}

.section {
  padding: 10px;
}

.expand {
  flex-grow: 1;
}
</style>
