<template>
  <div id="app">
    <div id="app-body">
      <router-view></router-view>
    </div>
    <span id="version">BUILD {{ version }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    return {
      version: `${process.env.VUE_APP_VERSION}_${GITCOMMITHASH}`,
    };
  },
});
</script>

<style lang="scss">
html,
body {
  font-family: 'Avenir next', sans-serif !important;
}

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-dialog {
  border-radius: 12px !important;

  &__footer {
    padding: 0 1rem 1rem 1rem !important;

    & > * {
      display: flex;
      justify-content: space-between;
    }
  }
}

.el-card {
  border-radius: 12px;
  box-shadow: 0 10px 16px -10px rgba(0, 0, 0, 0.05);
}

.el-popup-parent--hidden .el-dialog__body {
  padding: 0px 20px 10px;
}
</style>

<style lang="scss" scoped>
@import './variables';
@import './mixins';

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  // overflow: hidden;
}
#app {
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  background-color: $base;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
}

#app-body {
  width: 100%;
  height: $full-height;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.app-name.lab-editor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  background: white;
  border: solid 1px rgba(0, 0, 0, 0.05);
  // box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  width: 32px;
  height: 32px;
  border-radius: 6px;
}

#version {
  opacity: 0.5;
  font-size: 8pt;
  margin-left: 6px;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

#back-button {
  z-index: 10000;
  width: 100px;
  padding: 3px;
  margin-right: 12px;
}
</style>
