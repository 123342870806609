<template>
  <el-dialog
    class="confirm-modal"
    width="500px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :visible="visible"
    @close="cancel"
    v-bind="$attrs"
  >
    <slot>
      <div>{{ message }}</div>
      <br />
      <div>
        Type <i>{{ input }}</i> to proceed.
      </div>
      <el-input
        placeholder=""
        v-model="userInput"
        size="mini"
        @keyup.enter.native="confirm"
      />
    </slot>
    <span slot="footer" class="dialog-footer">
      <el-button id="close-button" @click.native="cancel">
        {{ cancelLabel }}
      </el-button>
      <el-button
        id="save-button"
        type="danger"
        :loading="loading"
        :disabled="input !== userInput"
        @click.native="confirm"
      >
        {{ confirmLabel }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { Dialog } from 'element-ui';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    input: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: 'cancel',
    },
    confirmLabel: {
      type: String,
      default: `i know what i'm doing`,
    },
    beforeCancel: {
      type: Function as PropType<() => void>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    beforeConfirm: {
      type: Function as PropType<() => void>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  components: {
    Dialog, // eslint-disable-line vue/no-reserved-component-names, vue/no-unused-components
  },
  data() {
    return {
      loading: false,
      userInput: '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      resolvePromise: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      rejectPromise: () => {},
    };
  },
  methods: {
    async cancel() {
      // typescript bug allows beforeCancel to be null
      // https://github.com/vuejs/vue/pull/11223
      if (this.beforeCancel) {
        await this.beforeCancel();
      }
      this.$emit('update:visible', false);
      this.rejectPromise();
    },
    async confirm() {
      if (this.input !== this.userInput) {
        return;
      }

      // typescript bug allows beforeConfirm to be null
      // https://github.com/vuejs/vue/pull/11223
      if (this.beforeConfirm) {
        this.loading = true;
        await this.beforeConfirm();
        this.loading = false;
      }

      this.$emit('update:visible', false);
      this.resolvePromise();
    },
  },
});
</script>
