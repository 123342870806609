import { reactive, set } from '@vue/composition-api';
import * as Service from './service';
import { Urdf } from './model';
import { prepareToExport } from './model';

interface StateInterface {
  data: {
    [key: number]: Urdf;
  };
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    // STATE - can't add to base object for reactive vue object
    this.state = reactive({ data: {} });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  // private
  addUrdf(urdf: Urdf) {
    set(this.state.data, urdf.id, urdf);
  }

  // ACTIONS/MUTATIONS
  async dispatchCreateUrdf(files) {
    if (files.length === 0) {
      throw 'no files selected';
    }

    const urdf = await Service.createUrdf(files);
    this.addUrdf(urdf);
    return urdf;
  }

  // HELPERS
  async exportUrdfs() {
    return Object.values(this.state.data).map(urdf => prepareToExport(urdf));
  }

  // GETTERS
  get urdfs(): { [key: number]: Urdf } {
    return this.state.data;
  }

  get urdfOptions() {
    return Object.values(this.state.data).map(urdf => ({
      id: urdf.id,
      name: urdf.name,
    }));
  }
}
