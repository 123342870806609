import { ApolloClient, HttpLink } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import { hostname } from '../utils';

export const GRAPHQL_URL =
  RUNTIME_GRAPHQL_URL === '{{ RUNTIME_GRAPHQL_URL }}'
    ? process.env.VUE_APP_GRAPHQL_URL
    : RUNTIME_GRAPHQL_URL;

const httpLink = new HttpLink({
  uri: hostname(GRAPHQL_URL),
  credentials: 'include',
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      AssetDefinition: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: ['id', 'vizVersion'],
      },
    },
  }),
});
