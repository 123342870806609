<template>
  <el-container>
    <el-input
      :maxlength="SIGFIGS"
      v-model="dimensions[0]"
      @input="updateDimension($event, 0)"
      placeholder=""
    >
      <div slot="suffix">{{ UNIT }}</div>
    </el-input>
    <div class="padded">×</div>
    <el-input
      :maxlength="SIGFIGS"
      v-model="dimensions[1]"
      @input="updateDimension($event, 1)"
      placeholder=""
    >
      <div slot="suffix">{{ UNIT }}</div>
    </el-input>
    <div class="padded">×</div>
    <el-input
      :maxlength="SIGFIGS"
      v-model="dimensions[2]"
      @input="updateDimension($event, 2)"
      placeholder=""
    >
      <div slot="suffix">{{ UNIT }}</div>
    </el-input>
  </el-container>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: Array,
  },
  setup() {
    return {
      UNIT: 'meters',
      SIGFIGS: 5,
    };
  },
  data() {
    return {
      dimensions:
        /* eslint-disable @typescript-eslint/no-explicit-any*/
        this.value && this.value.length
          ? this.value.map((x: any) => x.toString())
          : ['', '', ''],
    };
  },
  watch: {
    value(newVal) {
      this.dimensions =
        /* eslint-disable @typescript-eslint/no-explicit-any*/
        newVal && newVal.length
          ? newVal.map((x: any) => x.toString())
          : ['', '', ''];
    },
  },
  methods: {
    updateDimension(value, index) {
      const newDimensions = [...this.dimensions];
      newDimensions[index] = value;
      this.$emit('update:value', newDimensions);
    },
  },
});
</script>

<style lang="scss">
.el-container :not(:last-child) {
  margin-right: 10px;
}
</style>
