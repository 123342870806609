import axios from 'axios';

export const AUTH_URL =
  RUNTIME_AUTH_URL === '{{ RUNTIME_AUTH_URL }}'
    ? process.env.VUE_APP_AUTH_URL
    : RUNTIME_AUTH_URL;

export async function userInfo(): Promise<void> {
  // first refresh
  await axios.get(`${AUTH_URL}/refresh`, {
    withCredentials: true,
  });

  const response = await axios.get(`${AUTH_URL}/userInfo`, {
    withCredentials: true,
  });

  if (response.status === 401) {
    throw response.data.error;
  }
  return;
}
