export interface CommonGql {
  metadata?: {
    key: string;
    value: string;
  }[];
  created: {
    nanos: number;
    seconds: number;
  };
  revision?: string;
}

export interface CommonJson extends Omit<CommonGql, 'metadata'> {
  metadata: { [key: string]: string };
}

export function isoString(timestamp: {
  seconds: number;
  nanos: number;
}): string {
  if (!timestamp) {
    return new Date().toISOString();
  }

  let nanos = 0;
  if (timestamp.nanos) {
    nanos = timestamp.nanos / 1e6;
  }
  let unix = nanos;
  if (timestamp.seconds) {
    unix += parseInt(timestamp.seconds.toString()) * 1000;
  }
  return new Date(unix).toISOString();
}

export function cleanTypeName(json) {
  const copy = JSON.parse(JSON.stringify(json));
  delete copy['__typename'];
  for (const key in copy) {
    if (typeof copy[key] === 'object' && copy[key] !== null) {
      copy[key] = cleanTypeName(copy[key]);
    }
  }
  return copy;
}

export function camelToSnake(key: string): string {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result
    .split(' ')
    .join('_')
    .toLowerCase();
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function snakeCasify(json): { [key: string]: any } {
  const map = {};
  for (const key in json) {
    if (
      typeof json[key] === 'object' &&
      !Array.isArray(json[key]) &&
      json[key] !== null
    ) {
      map[camelToSnake(key)] = snakeCasify(json[key]);
    } else {
      map[camelToSnake(key)] = json[key];
    }
  }
  return map;
}

type MapArray = { key: string; value: string }[];
type MapDict = { [key: string]: string };
export function convertMapFromArray(map: MapArray): MapDict {
  const dict = {};
  map.forEach(({ key, value }) => (dict[key] = value));
  return dict;
}

export function convertMapToArray(map: MapDict): MapArray {
  return Object.entries(map).map(([key, value]) => ({ key, value }));
}

export function convertArrayToArray<T>(obj: { [key: number]: T }): T[] {
  const array = [] as T[];
  for (let i = 0; i < Object.keys(obj).length; i++) {
    array.push(obj[i]);
  }
  return array;
}
