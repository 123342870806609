import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthController from '@/clients/auth';
import Home from '../pages/Home.vue';
import Loading from '@/pages/Loading.vue';
import { AUTH_URL } from '@/clients/auth/service';
import * as NAMES from './names';

Vue.use(VueRouter);

const homeRoute = {
  path: '/',
  name: NAMES.HOME_ROUTE,
  component: Home,
  meta: {
    title: 'Asset Definition Service',
    requiresAuth: true,
    icon: '/favicon-a-lab.png',
  },
};

const routes = [
  {
    path: '/loading',
    component: Loading,
    name: NAMES.LOADING_ROUTE,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'entry',
    children: [
      {
        path: '/sign-in',
        name: NAMES.ENTRY_SIGN_IN_ROUTE,
        beforeEnter() {
          location.href = `${AUTH_URL}/login?redirect_uri=${location.href}`;
        },
      },
      {
        path: '/sign-out',
        name: NAMES.ENTRY_SIGN_OUT_ROUTE,
        beforeEnter() {
          location.href = `${AUTH_URL}/logout?redirect_uri=${location.href}`;
        },
      },
    ],
  },
  homeRoute,
];

const router = new VueRouter({ routes });

const requestedRoute = {
  name: homeRoute.name,
  params: {},
  meta: homeRoute.meta,
};

router.beforeEach((to, from, next) => {
  if (!AuthController.Instance.known) {
    // If loggedIn is not known, save the requested route and load a spinner
    if (to.name === NAMES.LOADING_ROUTE) {
      next();
    } else {
      requestedRoute.name = to.name || '';
      requestedRoute.params = to.params;
      requestedRoute.meta = to.meta;
      next({ name: NAMES.LOADING_ROUTE, replace: true });
    }
  } else {
    // Once loggedIn is known, change to earlier requested route
    // changing favicon
    if (to.meta.icon) {
      const favicon = to.meta.icon;
      const link = document.querySelector("[rel='icon']");
      if (link) link.setAttribute('href', favicon);
    }

    // change titles
    // Note:  This may be further changed by specific pages.
    if (to.meta.title)
      document.title = to.meta.title || 'Asset Definition Service';

    if (to.name === NAMES.CALCULATE_ROUTE_ON_LOGIN) {
      // Should only be called when the loggedIn state becomes known.
      // Attempt to redirect to the page attempted before loggedIn was known.
      if (requestedRoute.meta && requestedRoute.meta.requiresAuth) {
        if (AuthController.Instance.loggedIn) {
          next({ ...requestedRoute, replace: true });
        } else {
          next({ name: NAMES.ENTRY_SIGN_IN_ROUTE, replace: true });
        }
      } else {
        next({ ...requestedRoute, replace: true });
      }
    } else if (to.name === NAMES.LOADING_ROUTE) {
      // Handle special routes which should only be used when loggedIn is unknown.
      next({ name: NAMES.HOME_ROUTE, replace: true });
    } else if (AuthController.Instance.loggedIn) {
      if (to.name === NAMES.ENTRY_SIGN_OUT_ROUTE) {
        next();
      } else if ((to.name || '').includes('entry')) {
        next({ name: NAMES.HOME_ROUTE, replace: true });
      } else {
        next();
      }
    } else {
      if ((to.meta && to.meta.requiresAuth) || (to.name || '') === 'entry') {
        next({ name: NAMES.ENTRY_SIGN_IN_ROUTE, replace: true });
      } else {
        next();
      }
    }
  }
});

export default router;
