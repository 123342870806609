import { Urdf } from '@/clients/apollo/urdfs/model';
import { CommonGql, CommonJson, camelToSnake } from '@/clients/apollo/model';
import {
  cleanTypeName,
  isoString,
  snakeCasify,
  convertMapFromArray,
  convertArrayToArray,
  convertMapToArray,
} from '../model';
import { TYPES, PROPERTIES } from '@/config/properties';

export interface AssetDefinitionGql {
  id: string;
  brand: string;
  model: string;
  name: string;
  dimensions: number[];
  urdfId: number;
  urdf: Urdf;
  published: boolean;
  vizVersion: string;
  common: CommonGql;
}

export interface AssetDefinitionJson
  extends Omit<AssetDefinitionGql, 'common'> {
  common: CommonJson;
}

export function gqlToJson(
  assetDefinition: AssetDefinitionGql
): AssetDefinitionJson {
  const cleaned = cleanTypeName(assetDefinition);
  if (cleaned.common.metadata) {
    cleaned.common.metadata = convertMapFromArray(cleaned.common.metadata);
  }
  cleaned.dimensions = convertArrayToArray(cleaned.dimensions);
  return cleaned;
}

export function jsonToGql(
  assetDefinition: AssetDefinitionJson
): AssetDefinitionGql {
  const copy = JSON.parse(JSON.stringify(assetDefinition));
  copy.common.metadata = convertMapToArray(assetDefinition.common.metadata);
  return copy;
}

export function prepareToExport(assetDefinition: AssetDefinitionJson) {
  const json = snakeCasify(assetDefinition);
  json.common.created = isoString(assetDefinition.common.created);
  if (json.common.metadata && json.common.metadata.legacy) {
    json.common.metadata.legacy = JSON.parse(json.common.metadata.legacy);
  } else if (!json.common.metadata) {
    json.common.metadata = { legacy: {} };
  }
  for (const property of PROPERTIES) {
    const snakeId = camelToSnake(property.id);
    if (!TYPES[property.type].nullish(json[snakeId])) {
      json.common.metadata[property.id] = json[snakeId];
    }
    delete json[snakeId];
  }
  delete json.urdf;
  return json;
}
