<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <el-tabs v-model="state.input">
    <el-tab-pane label="Choose from existing urdfs" name="id">
      <el-form-item label-width="0px">
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <el-select v-model="state.id" placeholder="urdf">
          <el-option
            v-for="urdf in urdfs"
            :key="urdf.id"
            :label="urdf.name"
            :value="urdf.id"
          />
        </el-select>
      </el-form-item>
    </el-tab-pane>
    <el-tab-pane label="Upload urdf" name="files">
      <el-form-item label-width="0px">
        <el-container>
          <input
            ref="fileBrowser"
            @change="processFile"
            type="file"
            webkitdirectory
          />
          <el-button
            round
            type="primary"
            @click="clickImport"
            :loading="uploading"
            >upload</el-button
          >
        </el-container>
        <el-upload action="" :auto-upload="false" :file-list="state.files" />
      </el-form-item>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import UrdfController from '@/clients/apollo/urdfs';

interface File {
  name: string;
  data: ArrayBuffer;
}

const FILES_BLOCKLIST = ['.DS_Store'];
const DIR_CHARS_BLOCKLIST = [' '];

export default defineComponent({
  props: {
    state: Object,
  },
  data() {
    return {
      uploading: false,
    };
  },
  computed: {
    urdfs: function() {
      return UrdfController.Instance.urdfOptions;
    },
  },
  methods: {
    clickImport() {
      const fileBrowser = this.$refs.fileBrowser as HTMLInputElement | null;
      if (fileBrowser) {
        fileBrowser.click();
      }
    },

    processFile(e) {
      if (!e.target.files.length) {
        return;
      }

      this.uploading = true;
      Promise.all(
        [...e.target.files]
          .filter(file => {
            for (const blocklisted of FILES_BLOCKLIST) {
              if (file.name.includes(blocklisted)) {
                return false;
              }
            }
            for (const blocklisted of DIR_CHARS_BLOCKLIST) {
              if (file.webkitRelativePath.includes(blocklisted)) {
                return false;
              }
            }
            return true;
          })
          .map(file =>
            file.arrayBuffer().then(results => {
              return {
                name: file.webkitRelativePath,
                data: results as ArrayBuffer,
              };
            })
          )
      ).then((results: File[]) => {
        if (results.length === 0) {
          this.$emit('update:state', {
            ...this.state,
            error: Error(
              'No files selected. Directory may contain invalid characters or files'
            ),
          });
          this.uploading = false;
          return;
        }
        const name = results[0].name.split('/')[0];
        this.$emit('update:state', {
          ...this.state,
          input: 'files',
          name,
          files: results,
        });
        this.uploading = false;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.el-tabs {
  width: 100%;
}

input[type='file'] {
  display: none;
  height: 0;
  width: 0;
}
</style>

<style lang="scss">
.el-upload--text {
  display: block;
}
</style>
