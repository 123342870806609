const emptyArray = input => {
  for (const i of input) {
    if (i.toString().trim().length !== 0) return false;
  }
  return true;
};

export const TYPES = {
  float: {
    component: 'el-input',
    nullish: input => !input,
    valid: input => {
      const parsed = parseFloat(input);
      if (isNaN(parsed)) {
        throw new Error(' should be a float');
      }
      return true;
    },
    clean: input => {
      return parseFloat(input);
    },
  },
  int: {
    component: 'el-input',
    nullish: input => !input,
    valid: input => {
      const parsed = parseInt(input);
      if (isNaN(parsed)) {
        throw new Error(' should be an integer');
      }
      return true;
    },
    clean: input => {
      return parseInt(input);
    },
  },
  color: {
    component: 'el-color-picker',
    nullish: input => !input,
    valid: () => true,
    clean: input => input.trim(),
  },
  dimensions: {
    component: 'dimension-input',
    nullish: emptyArray,
    valid: input => {
      if (emptyArray(input)) {
        return true;
      }
      for (const i of input) {
        const parsed = parseFloat(i);
        if (isNaN(parsed)) {
          throw new Error(' should all be floats');
        }
      }
      return true;
    },
    clean: input => {
      if (emptyArray(input)) return [];
      return input.map(x => parseFloat(x));
    },
  },
};

export const PROPERTIES = [
  {
    id: 'dimensions',
    name: 'dimensions',
    type: 'dimensions',
    batchable: true,
  },
  {
    id: 'topOverlap',
    name: 'top overlap',
    type: 'float',
    unit: 'mm',
    batchable: true,
  },
  {
    id: 'bottomOverlap',
    name: 'bottom overlap',
    type: 'float',
    unit: 'mm',
    batchable: true,
  },
  {
    id: 'color',
    name: 'color (optional)',
    type: 'color',
    batchable: false,
  },
  {
    id: 'slotsRequired',
    name: 'slots required',
    type: 'int',
    batchable: true,
  },
];
