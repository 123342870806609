<template>
  <div class="margined">
    <confirm-modal
      title="Are you sure?"
      :message="message"
      :input="command"
      :beforeConfirm="handleCommand"
      :visible.sync="showModal"
    />
    <el-dropdown @command="updateCommand">
      <el-button type="danger" plain round>
        Expert mode<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="delete">Delete</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import AssetDefinitionController from '@/clients/apollo/assetDefinitions';
import { AssetDefinitionJson } from '@/clients/apollo/assetDefinitions/model';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default defineComponent({
  components: {
    ConfirmModal,
  },
  props: {
    selection: {
      type: Object as PropType<AssetDefinitionJson>,
      required: true,
    },
  },
  data() {
    return {
      command: '',
      showModal: false,
    };
  },
  computed: {
    message(): string {
      return `Are you sure you'd like to ${this.command} ${this.selection.id}:${this.selection.vizVersion}? This action is IRREVERSIBLE.`;
    },
  },
  methods: {
    updateCommand(command) {
      this.command = command;
      this.showModal = true;
    },
    async handleCommand() {
      switch (this.command) {
        case 'delete':
          await AssetDefinitionController.Instance.dispatchDeleteAssetDefinition(
            this.selection
          );
      }
    },
  },
});
</script>
