<template>
  <el-dialog
    class="modal"
    :visible="open"
    :title="title"
    top="100px"
    width="512px"
    @close="close"
  >
    <el-form label-position="left" label-width="120px">
      <div v-if="mode !== 'batch'">
        <el-form-item label="id">
          <el-container>
            <el-input v-model="state.id" :disabled="true" />
          </el-container>
        </el-form-item>
        <el-form-item label="brand">
          <el-container>
            <el-input v-model="state.brand" />
          </el-container>
        </el-form-item>
        <el-form-item label="model">
          <el-container>
            <el-input v-model="state.model" />
          </el-container>
        </el-form-item>

        <el-form-item label="name">
          <el-container>
            <el-input v-model="state.name" />
          </el-container>
        </el-form-item>
        <el-form-item label="viz version">
          <el-container>
            <el-input v-model="state.vizVersion" />
          </el-container>
        </el-form-item>
        <el-form-item label="metadata">
          <el-input
            type="textarea"
            autosize
            v-model="state.common.metadata.legacy"
            placeholder="misc properties"
          />
        </el-form-item>
      </div>
      <el-form-item
        v-for="property in PROPERTIES.filter(
          p => mode !== 'batch' || p.batchable
        )"
        :key="property.id"
        :label="property.name"
      >
        <component
          :is="TYPES[property.type].component"
          v-model="state[property.id]"
          :value.sync="state[property.id]"
        >
          <div slot="suffix">{{ property.unit }}</div>
        </component>
      </el-form-item>
      <urdf-picker :state.sync="urdfState" />
    </el-form>
    <el-alert
      v-if="error"
      :title="error.message"
      type="error"
      :closable="false"
    />
    <div slot="footer">
      <el-button round @click="close">cancel</el-button>
      <el-button round type="primary" @click="create">{{
        mode === 'new' ? 'create' : 'update'
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import DimensionInput from './DimensionInput.vue';
import UrdfPicker from './UrdfPicker.vue';
import AssetDefinitionController from '@/clients/apollo/assetDefinitions';
import { AssetDefinitionJson } from '@/clients/apollo/assetDefinitions/model';
import { TYPES, PROPERTIES } from '@/config/properties';

function resetState() {
  const state = {
    id: '',
    brand: '',
    model: '',
    name: '',
    common: { metadata: { legacy: '{}' } },
    vizVersion: '^1.0.0',
  };

  for (const property of PROPERTIES) {
    state[property.id] = null as string | null;
  }

  return {
    loading: false,
    error: null as Error | null,
    title: '',
    nameUntouched: true,
    state,
    urdfState: {
      input: 'id',
      id: 1,
      name: '',
      files: [],
      error: null as Error | null,
    },
    PROPERTIES,
    TYPES,
  };
}

export default defineComponent({
  props: {
    open: Boolean,
    initialState: {
      type: Array as PropType<AssetDefinitionJson[]>,
      required: true,
    },
  },
  components: {
    DimensionInput,
    UrdfPicker,
  },
  data() {
    return resetState();
  },
  computed: {
    mode(): string {
      if (!this.initialState) {
        return '';
      }
      if (this.initialState.length === 0) {
        this.title = 'Create New Asset Definition'; // eslint-disable-line vue/no-side-effects-in-computed-properties
        return 'new';
      } else if (this.initialState.length === 1) {
        this.title = 'Update Asset Definition'; // eslint-disable-line vue/no-side-effects-in-computed-properties
        return 'update';
      } else {
        this.title = 'Batch Update Asset Definitions'; // eslint-disable-line vue/no-side-effects-in-computed-properties
        return 'batch';
      }
    },
  },
  watch: {
    'state.brand': function() {
      this.generateId();
    },
    'state.model': function() {
      this.generateId();
    },
    'state.name': function() {
      if (this.state.name !== `${this.state.brand} ${this.state.model}`) {
        this.nameUntouched = false;
      }
    },
    'state.common.metadata.legacy': function() {
      this.generateId();
    },
    initialState: function(newVal: AssetDefinitionJson[]) {
      this.clear();
      if (newVal.length === 1 && newVal[0]) {
        const metadata = newVal[0].common.metadata;
        const checkedProperties = {};

        for (const property of PROPERTIES) {
          checkedProperties[property.id] = newVal[0][property.id]
            ? newVal[0][property.id]
            : this.state[property.id];
        }

        this.state = {
          ...this.state,
          id: newVal[0].id,
          brand: newVal[0].brand,
          model: newVal[0].model,
          name: newVal[0].name,
          vizVersion: newVal[0].vizVersion,
          common: {
            metadata: {
              legacy: JSON.stringify(
                JSON.parse(metadata.legacy ? metadata.legacy : '{}'),
                null,
                2
              ),
            },
          },
          ...checkedProperties,
        };
        this.urdfState = {
          ...this.urdfState,
          input: 'id',
          id: newVal[0].urdfId,
        };
      }
    },
    'urdfState.error': function(error: Error) {
      if (error) {
        this.error = error;
      }
    },
  },
  methods: {
    generateId() {
      if (this.mode === 'new') {
        let eqType = 'equipment';
        try {
          const parsed = JSON.parse(this.state.common.metadata.legacy);
          if (parsed.container) {
            eqType = 'container';
          }
          // eslint-disable-next-line
        } catch (err) {}

        const def = eqType + 'Definition';
        this.state.id =
          def +
          '_' +
          (this.state.brand.trim() + '_' + this.state.model.trim())
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '_');
        if (this.nameUntouched) {
          this.state.name = this.state.brand + ' ' + this.state.model;
        }
      }
    },
    create() {
      this.error = null;
      this.loading = true;

      const savedState = {
        ...this.state,
      };

      // eslint-disable-next-line
      let assetsToCreate = [{...this.state}] as any[];

      if (this.mode === 'batch') {
        assetsToCreate = this.initialState.map(assetDefinition => ({
          ...assetDefinition,
        }));
      }

      for (const assetDefinition of assetsToCreate) {
        delete assetDefinition.revision;
        for (const property of PROPERTIES) {
          try {
            if (
              this.state[property.id] &&
              TYPES[property.type].valid(this.state[property.id]) &&
              // if it's batch mode, don't override if it's an empty value
              (this.mode !== 'batch' ||
                !TYPES[property.type].nullish(this.state[property.id]))
            ) {
              assetDefinition[property.id] = this.state[property.id];
            }
          } catch (err) {
            Object.assign(this.$data, savedState);
            this.error = new Error(`${property.id}${err}`);
            this.loading = false;
            return;
          }
        }
      }

      AssetDefinitionController.Instance.dispatchUpsertAssetDefinitions(
        assetsToCreate,
        this.urdfState
      )
        .then(() => {
          this.close();
          this.loading = false;
        })
        .catch(err => {
          Object.assign(this.$data, savedState);
          this.error = err;
          this.loading = false;
        });
    },
    clear() {
      Object.assign(this.$data, resetState());
    },
    close() {
      this.clear();
      this.$emit('update:open', false);
    },
  },
});
</script>

<style lang="scss">
.el-container :not(:last-child) {
  margin-right: 10px;
}
</style>
