import Vue from 'vue';
import VueCompositionAPI, { watch } from '@vue/composition-api';
import AuthController from '@/clients/auth';
import AssetDefinitionController from '@/clients/apollo/assetDefinitions';

import router from './router';
import App from './App.vue';
import './config';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);

// AuthController needs access to router to initiate route changes
// when user logs in or out.
AuthController.Instance.router = router;
AuthController.Instance.dispatchLoggedIn();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

watch(
  () => AuthController.Instance.loggedIn,
  isLoggedIn => {
    if (isLoggedIn) {
      AssetDefinitionController.Instance.dispatchListAssetDefinitions();
    }
  },
  { immediate: true }
);
