import graphql from './urdfs.gql';
import { Urdf } from './model';
import { client } from '../client';
import { gqlToJson, filesJsonToGql } from './model';

export async function createUrdf(files): Promise<Urdf> {
  const { data, errors } = await client.mutate<{ createUrdf: Urdf }>({
    mutation: graphql.createUrdf,
    variables: {
      files: filesJsonToGql(files),
    },
  });

  if (data) {
    return gqlToJson(data.createUrdf);
  } else {
    throw errors;
  }
}
