import { saveAs } from 'file-saver';

export function formatDate(date: Date): string {
  function pad(number: number): string {
    return `00${number}`.slice(-2);
  }
  return `${date.getFullYear()}${pad(date.getMonth())}${pad(
    date.getDate()
  )}_${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
}
export function downloadJson(data) {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'text/json;charset=utf-8',
  });
  saveAs(blob, `asset_definitions_${formatDate(new Date())}.json`);
}

export const NO_URDF = 'no urdf';
