import { cleanTypeName, isoString, snakeCasify } from '../model';
import { CommonGql } from '@/clients/apollo/model';

export interface Urdf {
  id: string;
  name: string;
  path: string;
  url?: string;
  common: CommonGql;
}

export function gqlToJson(urdf: Urdf): Urdf {
  return cleanTypeName(urdf);
}

export function jsonToGql(urdf: Urdf): Urdf {
  return urdf;
}

export function filesJsonToGql(files) {
  return files.map(file => ({
    name: file.name,
    data: Array.from(new Uint8Array(file.data)),
  }));
}

export function prepareToExport(urdf: Urdf) {
  const json = snakeCasify(urdf);
  json.common.created = isoString(urdf.common.created);
  if (json.path.length === 0) {
    delete json.path;
  }
  delete json.url;
  return json;
}
