import { reactive, set, del } from '@vue/composition-api';
import UrdfController from '@/clients/apollo/urdfs';
import * as Service from './service';
import semverMajor from 'semver/functions/major';
import semverValid from 'semver/functions/valid';
import { AssetDefinitionJson } from './model';
import { prepareToExport } from './model';
import { TYPES, PROPERTIES } from '@/config/properties';

interface StateInterface {
  data: {
    [major: string]: {
      [vizVersion: string]: AssetDefinitionJson;
    };
  };
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;

  private constructor() {
    // STATE - can't add to base object for reactive vue object
    this.state = reactive({ data: {} });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  assetDefinitionKey(assetDefinition: AssetDefinitionJson) {
    const major = semverMajor(assetDefinition.vizVersion.substring(1));
    return `${assetDefinition.id}-${major}`;
  }

  // private
  setAssetDefinition(assetDefinition: AssetDefinitionJson) {
    const key = this.assetDefinitionKey(assetDefinition);
    if (!(key in this.state.data)) set(this.state.data, key, {});
    set(this.state.data[key], assetDefinition.common.revision, assetDefinition);
    UrdfController.Instance.addUrdf(assetDefinition.urdf);
  }

  deleteAssetDefinition(assetDefinition: AssetDefinitionJson) {
    const key = this.assetDefinitionKey(assetDefinition);
    del(this.state.data[key], assetDefinition.common.revision);
    if (Object.keys(this.state.data[key]).length === 0) {
      del(this.state.data, key);
    }
  }

  validate(assetDefinition) {
    // nothing is blank
    if (!assetDefinition.id.trim()) {
      throw new Error("id can't be blank");
    }

    if (!assetDefinition.name.trim()) {
      throw new Error("name can't be blank");
    }

    if (!assetDefinition.vizVersion.trim()) {
      throw new Error("viz version can't be blank");
    }

    if (
      assetDefinition.vizVersion.trim().charAt(0) !== '^' &&
      !semverValid(assetDefinition.vizVersion.trim().substring(0))
    ) {
      throw new Error('viz version must be of format ^x.x.x');
    }

    // check numbers are valid
    for (const property of PROPERTIES) {
      if (
        assetDefinition[property.id] &&
        assetDefinition[property.id].toString().trim().length
      ) {
        try {
          assetDefinition[property.id] = TYPES[property.type].clean(
            assetDefinition[property.id]
          );
        } catch (err) {
          // @ts-expect-error don't know don't care
          throw new Error(`${property.name} ${err.message}`);
        }
      } else {
        delete assetDefinition[property.id];
      }
    }

    try {
      JSON.parse(assetDefinition.common.metadata.legacy);
    } catch (err) {
      // @ts-expect-error don't know don't care
      throw new Error(`Metadata is invalid JSON: ${err.message}`);
    }

    if (assetDefinition) {
      delete assetDefinition.urdf;
    }
  }

  // ACTIONS / MUTATIONS
  async dispatchUpsertAssetDefinitions(assetDefinitions, urdf) {
    // do some client side data checking
    assetDefinitions.forEach(assetDefinition => this.validate(assetDefinition));

    if (urdf && urdf.files.length) {
      const urdfUpload = await UrdfController.Instance.dispatchCreateUrdf(
        urdf.files
      );
      urdf.id = urdfUpload.id;
    }

    await Promise.all(
      assetDefinitions.map(async assetDefinitionInput => {
        const assetDefinition = await Service.upsertAssetDefinition({
          ...assetDefinitionInput,
          urdfId: urdf ? urdf.id : assetDefinitionInput.urdfId,
        });
        this.setAssetDefinition(assetDefinition);
      })
    );
  }

  async dispatchListAssetDefinitions() {
    if (Object.keys(this.state.data).length === 0) {
      const list = await Service.listAssetDefinitions();
      for (const assetDefinition of list) {
        this.setAssetDefinition(assetDefinition);
      }
    }
    return this.state.data;
  }

  async dispatchDeleteAssetDefinition(assetDefinition) {
    await Service.deleteAssetDefinition(
      assetDefinition.id,
      assetDefinition.vizVersion
    );
    this.deleteAssetDefinition(assetDefinition);
  }

  // HELPERS
  async exportAssetDefinitions() {
    const assetDefinitions = await Service.listAssetDefinitions();
    assetDefinitions.sort((a, b) => {
      return (
        parseInt(a.common.revision || '0') - parseInt(b.common.revision || '0')
      );
    });
    return assetDefinitions.map(assetDefinition =>
      prepareToExport(assetDefinition)
    );
  }

  // GETTERS
  get assetDefinitionsList(): { [version: string]: AssetDefinitionJson }[] {
    return Object.values(this.state.data);
  }
}
