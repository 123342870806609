import VueRouter from 'vue-router';
import { reactive } from '@vue/composition-api';
import * as Service from './service';
import axios from 'axios';
import { AUTH_URL } from '@/clients/auth/service';
import { CALCULATE_ROUTE_ON_LOGIN, ENTRY_SIGN_OUT_ROUTE } from '@/router/names';

interface State {
  known: boolean;
  loggedIn: boolean;
}

const AUTH_REFRESH_MIN = 30;

export default class Controller {
  private static instance: Controller;
  private state: State;
  private refreshInterval: NodeJS.Timeout | null = null; // NodeJS.Timeout
  private _router: VueRouter | null = null;
  private constructor() {
    this.state = reactive({
      known: false,
      loggedIn: false,
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  triggerRouteChange() {
    if (this._router) {
      this._router.replace({ name: CALCULATE_ROUTE_ON_LOGIN }).catch(() => {
        // Intentionally routing to 'CALCULATE_ROUTE_ON_LOGIN' which would determine where to send the user
        // depending if the user is logged in or not.  Vue-router treats any navigation guard
        // redirects as an error.  This catch will silence that unnecessary error.  More info:
        // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
      });
    }
  }

  // ACTIONS/MUTATIONS
  async dispatchLoggedIn() {
    try {
      await Service.userInfo();
      this.state.loggedIn = true;
      this.refreshInterval = setInterval(async () => {
        await axios.get(`${AUTH_URL}/refresh`, {
          withCredentials: true,
        });
      }, AUTH_REFRESH_MIN * 60 * 1000);
    } catch {
      this.state.loggedIn = false;
    } finally {
      this.state.known = true;
      this.triggerRouteChange();
    }
  }

  async dispatchLogout() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }

    if (this._router) {
      this._router.push({ name: ENTRY_SIGN_OUT_ROUTE });
    }

    this.state.known = true;
    this.state.loggedIn = false;
  }

  // GETTERS
  get known() {
    return this.state.known;
  }

  get loggedIn() {
    return this.state.loggedIn;
  }

  // SETTERS
  set router(router: VueRouter) {
    this._router = router;
  }
}
